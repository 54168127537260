import { ErrorHandler } from '@angular/core';
import { AppInjector } from '@pixels/client/app-injector/app.injector';
import { catchError, ObservableInput, ObservedValueOf, of, OperatorFunction } from 'rxjs';

export function globalCatchError<T, O extends ObservableInput<any>>(reuseCaught = true): OperatorFunction<T, T | ObservedValueOf<O>> {
  return source => source.pipe(
    catchError((err, caught) => {
      AppInjector.getInstance().getInjector().get(ErrorHandler).handleError(err);
      return reuseCaught ? caught : of(err);
    })
  );
}
